import React from "react";
import { SelectBox } from "../";
import { Grid, TextField, FormControl, Radio, RadioGroup, FormControlLabel } from "@material-ui/core";

const GetFieldBasedOnType = (props) => {
    const { val, index } = props
    let factName = props?.val?.name?.name ?? null;
    let factList = props.factsList.filter(val => val.name === factName)[0];
    let columnType = factList?.if?.columnType ?? null;
    switch (columnType) {
        case "number": {
            return <React.Fragment>
                <TextField inputProps={{ style: { height: 14 } }} type="number"
                    style={{ backgroundColor: 'white' }}
                    fullWidth variant="outlined" size="small"
                    placeholder="value" value={val.value}
                    onChange={e => props.stateUpdate("value", e.target.value, index)}
                />
            </React.Fragment>
        }
        case "boolean": {
            return <React.Fragment>
                <FormControl component="fieldset">
                    <RadioGroup value={val.value !== undefined ? (val.value + "") : null}
                        name="radio-buttons-group" style={{ display: "inline" }}
                        onChange={e => props.stateUpdate("value", e.target.value === "true" ? true : false, index)}
                    >
                        <FormControlLabel value="true" control={<Radio />} label="True" />
                        <FormControlLabel value="false" control={<Radio />} label="False" />
                    </RadioGroup>
                </FormControl>
            </React.Fragment>
        }
        default: {
            return <React.Fragment>
                <TextField inputProps={{ style: { height: 14 } }}
                    style={{ backgroundColor: 'white' }}
                    fullWidth variant="outlined" size="small"
                    placeholder="value" value={val.value}
                    onChange={e => props.stateUpdate("value", e.target.value, index)}
                />
            </React.Fragment>
        }

    }
}

function RuleSection(props) {

    const { val, index, factsList = [], updateRule, actionsList, ruleindex = 0 } = props;

    const stateUpdate = async (name, value, index) => {
        const data = JSON.parse(JSON.stringify(actionsList));
        data[index][name] = value
        updateRule(data, ruleindex);
    }

    const getNameList = () => {
        let arr = [];
        factsList.map(val => {
            if (val.name && val.if && val.in) {
                arr.push({
                    ...val,
                    label: val.name
                })
            }
            return false
        })
        return arr;
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid md={4} style={{ paddingRight: 15 }}>
                    <SelectBox
                        placeholder="name"
                        list={getNameList()}
                        value={val.name ? val.name : null}
                        onchange={(e, value) => { stateUpdate("name", value, index) }}
                    />
                </Grid>
                <Grid md={4} style={{ paddingRight: 15 }}>
                    <SelectBox
                        list={isList}
                        value={val.is}
                        onchange={(e, value) => { stateUpdate("is", value, index) }}
                        withText="Is"
                    />
                </Grid>
                <Grid md={4} style={{ paddingRight: 10, marginTop: 4 }}>
                    <GetFieldBasedOnType stateUpdate={stateUpdate} {...props} />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}



export default RuleSection;


const isList = [
    { label: "Equal", value: "equal" },
    { label: "Not Equal", value: "notEqual" },
    { label: "Greater Than", value: "greaterThan" },
    { label: "Less Than", value: "lessThan" },
    { label: "In", value: "in" },
    { label: "Contains", value: "contains" },
]