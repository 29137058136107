import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { Rule } from "./rule";
import { withAllContexts } from "../HOCs";
import { actions } from "rule-binder";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  rules: {
    padding: theme.spacing(2),
  },
  rulesCount: {
    width: "22px",
    height: "22px",
    background: "black",
    color: "white",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
    marginLeft: "6px",
  },
  rulesGroup: {
    display: "grid",
    gridGap: "30px",
    margin: "24px 0px",
  },
  noRules: {
    textAlign: "center",
    paddingTop: "20%"
  }
}));

function Rules(props) {
  const { groupsData } = props;
  const disPatch = useDispatch();
  const ruleList = useSelector(state => state.GroupsAndRulesSlice.rule_list.data);
  const { groupId } = groupsData;
  const classes = useStyles();
  React.useEffect(() => {
    if (groupId) {
      disPatch(actions.READ_RULE({ groupId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const callBack = () => {
    disPatch(actions.READ_RULE({ groupId }));
  }
  return (
    <div className={classes.rules}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6" style={{ fontSize: "18px" }} color="initial">
          Rules
        </Typography>
        {groupId && <div className={classes.rulesCount}>{ruleList.length}</div>}
      </div>
      {(!groupId || ruleList.length === 0) && <div className={classes.noRules}>No data Found</div>}
      <div className={classes.rulesGroup}>
        {ruleList?.map((eachRule) => (
          <Rule key={eachRule.ruleName} rule={eachRule} callBack={callBack} />
        ))}
      </div>
    </div>
  );
}


export default withAllContexts(Rules)