import React from "react";
import { Button, Grid, TextField, Typography, withStyles } from "@material-ui/core";
import style from "./addJson.json";
import {
    // ActionsResponse, 
    AddRuleProperty, AddFact
} from "../";
import { actions } from "rule-binder";
import { connect } from "react-redux";
import { ruleToJson } from "../../functions";
import { AlertProps } from "../../utils";
import { withAllContexts } from "../../HOCs";
const styles = theme => (style)

const intialaction = { action: null, priority: null };

// const defaultData = [{ "all": [{ "in": null, "if": null, "is": null, "value": null }, { "all": [{ "in": null, "if": null, "is": null, "value": null }, { "all": [{ "in": null, "if": null, "is": null, "value": null }, { "all": [{ "in": null, "if": null, "is": null, "value": null }, { "all": [{ "in": null, "if": null, "is": null, "value": null }] }] }] }] }] }, { "all": [{ "in": null, "if": null, "is": null, "value": null }, { "all": [{ "in": null, "if": null, "is": null, "value": null }] }] }, { "all": [{ "in": null, "if": null, "is": null, "value": null }] }]

// const defaultRule = [{"condition":"all","data":[{"in":null,"if":null,"is":null,"value":null},{"condition":"all","in":null,"if":null,"is":null,"value":null},{"condition":"all","in":null,"if":null,"is":null,"value":null},{"condition":"all","in":null,"if":null,"is":null,"value":null},{"condition":"all","in":null,"if":null,"is":null,"value":null}]},{"condition":"all","data":[{"in":null,"if":null,"is":null,"value":null},{"condition":"all","in":null,"if":null,"is":null,"value":null}]},{"condition":"all","data":[{"in":null,"if":null,"is":null,"value":null}]}];

class AddRule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actionTrue: [intialaction],
            actionFalse: [intialaction],
            ruleName: "",
            factsList: [{ name: null, attr: null, type: null }],
            // ruleProperty: defaultRule
            ruleProperty: [{ condition: "all", data: [{ is: null, value: null }] }]
        }
        this.state1 = {
            // "actionTrue": [
            //     {
            //         "action": null,
            //         "priority": null
            //     }
            // ],
            // "actionFalse": [
            //     {
            //         "action": null,
            //         "priority": null
            //     }
            // ],
            // "factsList": [
            //     {
            //         "name": "test",
            //         "attr": null,
            //         "type": null,
            //         "in": {
            //             "_id": "60513978",
            //             "name": "AcctEntity",
            //             "type": "DOCUMENT",
            //             "label": "AcctEntity"
            //         },
            //         "if": {
            //             "columnName": "id",
            //             "columnType": "number",
            //             "label": "id",
            //             "value": "id"
            //         }
            //     }
            // ],
            // "ruleProperty": [
            //     {
            //         "condition": "all",
            //         "data": [
            //             {
            //                 "is": {
            //                     "label": "Equal",
            //                     "value": "equal"
            //                 },
            //                 "value": "34",
            //                 "name": {
            //                     "name": "test",
            //                     "attr": null,
            //                     "type": null,
            //                     "in": {
            //                         "_id": "60513978",
            //                         "name": "AcctEntity",
            //                         "type": "DOCUMENT",
            //                         "label": "AcctEntity"
            //                     },
            //                     "if": {
            //                         "columnName": "id",
            //                         "columnType": "number",
            //                         "label": "id",
            //                         "value": "id"
            //                     },
            //                     "label": "test"
            //                 }
            //             }
            //         ]
            //     }
            // ]
        }
        this.updateState = this.updateState.bind(this)
        this.previewAndSave = this.previewAndSave.bind(this)
    }

    async componentDidMount() {
        await this.props.COLLECTIONS_LIST();
        let { uiMeta } = this?.props?.editData ?? {};
        if (uiMeta) {
            let cloneText = this.props.isCloning ? "-copy" : ""
            this.setState({
                actionTrue: uiMeta?.actionFalse,
                actionFalse: uiMeta?.actionTrue,
                ruleName: this?.props?.editData?.ruleName + "" + cloneText,
                factsList: uiMeta?.facts,
                ruleProperty: uiMeta?.property
            })
        }
    }

    updateState = (name, value) => {
        this.setState({ [name]: value })
    }

    previewAndSave = async () => {
        const state = this.state;
        if (!state.ruleName.trim()) {
            this.props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: `Please fill all mandatory fields!`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
            });
            return false
        }
        let json = ruleToJson(state.ruleProperty, state.factsList)
        let factsLists = state.factsList.map(val => {
            return {
                [val?.in?.label]: {
                    attr: val?.if?.label,
                    type: val?.if?.columnType
                }
            }
        })
        let payload = {
            "groupId": this.props?.groupsData?.groupId,
            "ruleName": state.ruleName.trim(),
            "ruleMeta": { any: json },
            "edit_id": this.props.isCloning ? null : this.props?.editData?._id ?? null,
            "uiMeta": {
                facts: state.factsList,
                property: state.ruleProperty,
                actionTrue: state.actionTrue,
                actionFalse: state.actionFalse
            },
            "facts": factsLists,
            "actionTrue": [],
            "actionFalse": [],
        }
        await this.props.UPSERT_RULE(payload)
        await this.props.READ_RULE({ groupId: this.props?.groupsData?.groupId })
        this.props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: `updated`,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
        });
        // var myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/json");
        // fetch("http://localhost:8080/rule-engine", {
        //     method: 'POST',
        //     headers: myHeaders,
        //     body: JSON.stringify({
        //         conditions: { any: json },
        //         factList: factsLists
        //     }),
        //     redirect: 'follow'
        // });
        // let json = jsonToRule(defaultData)
        this.props.handleClose()
    }

    render() {
        const { classes } = this.props;
        const {
            // actionTrue, actionFalse,
            ruleProperty, factsList, ruleName } = this.state;
        return (
            <Grid className={classes.root}>
                <Grid>
                    <Typography variant="h6" className={classes.title}>Add New Rule</Typography>
                </Grid>
                <Grid container>
                    <Grid md={6} className={classes.ruleName}>
                        <Typography className={classes.rulenameTitle} >Rule Name <span style={{ color: "red" }}>*</span></Typography>
                        <TextField fullWidth variant="outlined" size="small"
                            onChange={e => this.setState({ ruleName: e.target.value })}
                            value={ruleName}
                        />
                    </Grid>
                    <Grid md={12}>
                        <Typography variant="h6" className={classes.rulepropsTitle} >Facts :</Typography>
                    </Grid>
                    <Grid md={12} style={{ marginBottom: 15 }}>
                        <AddFact editData={this.props.editData} actionsList={factsList} stateName="factsList" setActions={this.updateState} />
                    </Grid>
                    <Grid md={12}>
                        <Typography variant="h6" className={classes.rulepropsTitle} >Condition :</Typography>
                    </Grid>
                    <Grid md={12}
                    // className={classes.rulesParent}
                    >
                        <AddRuleProperty factsList={factsList} propertyRule={ruleProperty} stateName={"ruleProperty"} addPoperty={this.updateState} />
                    </Grid>

                    {/* Actions - When False */}
                    {/* start */}
                    {/* <Grid md={12}>
                        <Typography variant="h6" className={classes.actionsTitle} >Actions - When True :</Typography>
                    </Grid>
                    <Grid md={12} className={classes.rulesParent}>
                        <ActionsResponse actionsList={actionTrue} stateName="actionTrue" setActions={this.updateState} />
                    </Grid> */}
                    {/* end */}

                    {/* Actions - When False */}
                    {/* start */}
                    {/* <Grid md={12}>
                        <Typography variant="h6" className={classes.actionsTitle} >Actions - When False :</Typography>
                    </Grid>
                    <Grid md={12} className={classes.rulesParent}>
                        <ActionsResponse actionsList={actionFalse} stateName="actionFalse" setActions={this.updateState} />
                    </Grid> */}
                    {/* end */}

                    {/* final actions */}
                    {/* Start */}
                    <Grid md={12} className={classes.actions}>
                        <Button variant="contained" onClick={() => this.previewAndSave()} style={{ marginRight: "10px" }} color="primary">PREVIEW & SAVE</Button>
                        <Button variant="outlined" color="primary" onClick={() => this.props.handleClose()}>CANCEL</Button>
                    </Grid>
                    {/* End */}
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    collection_list: state.ruleMastersSlice.collection_list
});

export default withStyles(styles)(connect(
    mapStateToProps,
    actions
)(withAllContexts(AddRule)))
