export const convert = (req) => {
    let res = [];
    if (Array.isArray(req)) {
        req.map(val => {
            let oprator = Object.keys(val)[0];
            if (dataType[oprator]) {
                res.push({
                    [dataType[oprator]]: convert(val[oprator])
                })
            } else {
                res.push({
                    oprator: oprator,
                    value: val[oprator][1],
                    name: val[oprator][0].var
                })
            }
            return false;
        })
    } else {
        Object.keys(req).map(val => {
            if (dataType[val]) {
                res.push({
                    [dataType[val]]: convert(req[val])
                })
            } else {
                res.push({
                    oprator: val,
                    value: val[val][1],
                    name: val[val][0].var
                })
            }
            return false
        })
    }

    return res
}

let dataType = {
    "!": "NOT",
    "and": "AND",
    "or": "OR",
}