import React from "react";
import { withStyles } from "@material-ui/core";
import { Header, MainAppBar, SideBar, Groups } from "../../components";

const styles = (theme) => {
  console.log(theme);
  return {
    main: {
      display: "grid",
      gridTemplateColumns: "80px 1fr",
    },
  };
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null };
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Header />
        <MainAppBar addRule />
        <div className={classes.main}>
          <SideBar />
          <Groups />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Home);
