/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/12/202 
 * @modify 12/12/202 
 * @desc Providing the ThemeContext from /src/context which is used in /src/App.js
 */

import React from "react";
import { GroupsContext } from "./";


const GroupsContextData = (props) => {

    let [groups, setGroups] = React.useState({
        groupId: null
    });

    return (
        <GroupsContext.Provider value={{ ...groups, setGroups }}>
            {props.children}
        </GroupsContext.Provider>
    );
};

export default GroupsContextData;