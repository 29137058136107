import React from "react";
import { ListItem, ListItemText, makeStyles } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { withAllContexts } from "../HOCs";

const useStyles = makeStyles((theme) => ({
  group: {
    borderRadius: "5px",
    padding: theme.spacing(0.8),
  },
  activeGroup: {
    backgroundColor: theme.palette.background.default,
  },
  groupName: {
    fontSize: "14px",
    marginLeft: theme.spacing(0.5),
  },
  groupMenuIcon: {
    fontSize: "18px",
  },
  activeGroupMenuIcon: {
    color: theme.palette.primary.main,
  },
}));

function Group(props) {
  const { groupName, value } = props.group;
  const selected = value === props.groupsData.groupId
  const classes = useStyles();
  return (
    <ListItem className={classes.group} button onClick={() => props.groupsData.setGroups({
      ...props.groupsData,
      groupId: value
    })}>
      {/* <DragIndicatorIcon style={{ fontSize: 16 }} /> */}
      <ListItemText
        classes={{ primary: classes.groupName }}
        primary={groupName}
      />
      <MoreVertIcon
        className={`${classes.groupMenuIcon} ${selected ? classes.activeGroupMenuIcon : ""
          }`}
      />
    </ListItem>
  );
}


export default withAllContexts(Group)