import React from "react";
import { Drawer, Grid, Avatar } from "@material-ui/core";
import styles from './style';
import {AddRule} from '../';

function CreateRule(props) {
    const classes = styles();
    const { open, handleClose = () => null } = props;
    return (
        <React.Fragment>
            <Drawer
                anchor={"right"}
                open={open}
                // onClose={() => handleClose(false)}
            >
                <Grid style={{ width: "700px" }}>
                    <Avatar src="/images/close.svg" className={classes.large} onClick={() => handleClose(false)} />
                    <AddRule {...props} />
                </Grid>
            </Drawer>
        </React.Fragment>
    )
}

export default CreateRule;