import React from 'react'
import {
  Grid, TextField, Typography, withStyles, Button,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import QueryBuilder from "./queryBuilder";
import { Header } from '../../components';
import { convert } from "../../functions";
import { Utils as QbUtils } from "react-awesome-query-builder";
// For Material-UI widgets only:
import MaterialConfig from "react-awesome-query-builder/lib/config/material";

const styles = theme => ({})

// Choose your skin (ant/material/vanilla):
const InitialConfig = MaterialConfig; // or MaterialConfig or BasicConfig

const config = {
  ...InitialConfig,
  fields: {
    qty: {
      label: "Qty",
      type: "number",
      fieldSettings: {
        min: 0
      },
      valueSources: ["value"],
      preferWidgets: ["number"]
    },
    price: {
      label: "Price",
      type: "number",
      valueSources: ["value"],
      fieldSettings: {
        min: 10,
        max: 100
      },
      preferWidgets: ["slider", "rangeslider"]
    },
    color: {
      label: "Color",
      type: "select",
      valueSources: ["value"],
      fieldSettings: {
        listValues: [
          { value: "yellow", title: "Yellow" },
          { value: "green", title: "Green" },
          { value: "orange", title: "Orange" }
        ]
      }
    },
    is_promotion: {
      label: "Promo?",
      type: "boolean",
      operators: ["equal"],
      valueSources: ["value"]
    }
  }
};


class AddRule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerName: "QDM",
      tree: null,
      ruleName: ""
    }
    this.updateState = this.updateState.bind(this)
  }

  GenerateQuery = () => {
    let data = QbUtils.jsonLogicFormat(this.state.tree, config)
    let dataList = convert(data.logic);
    this.setState({ openModal: true, generatedData: dataList })
  }

  updateState = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  handleClose = () => {
    this.setState({
      openModal: false
    })
  };

  render() {
    const { ruleName } = this.state;
    return (<React.Fragment>
      <Grid>
        <Header backBtn enableBtn getJson={() => this.GenerateQuery()} />
        <Grid style={{ padding: "20px 28px 18px" }}>
          <Typography variant="h6" style={{ paddingBottom: "6px", fontSize: "16px" }}> Rule Name </Typography>
          <TextField value={ruleName} onChange={e => this.setState({ ruleName: e.target.value })} variant="outlined" fullWidth />
        </Grid>
        <QueryBuilder config={config} updateState={this.updateState} />
        <Grid style={{ padding: "20px 28px 18px" }}>
          <Typography variant="h6" style={{ paddingBottom: "6px", fontSize: "16px" }}> Action </Typography>
          
        </Grid>
      </Grid>
      <Dialog
        open={this.state.openModal}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Build Json"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              JsonLogic:{" "}
              <pre>
                {JSON.stringify(this.state.generatedData)}
                {/* {JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))} */}
              </pre>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            okay
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
    )
  }
}

export default withStyles(styles)(withRouter(AddRule))
