import React from "react";
import {
  Typography, Menu,
  MenuItem, makeStyles, withStyles,
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import moment from "moment"
import { CreateRule } from "../components";

const useStyles = makeStyles((theme) => ({
  rule: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "55% 10% 30% 5%",
    padding: "12px 24px",
    background: "white",
    borderRadius: "8px",
    border: `1px solid ${theme.palette.grey["200"]}`,
    boxShadow: "0px 0px 10px 3px rgba(239,239,239,0.3)",
  },
  ruleInfo: {
    display: "grid",
    gridGap: "4px",
  },
  info: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  eachInfo: {
    display: "grid",
    gridGap: "6px",
    padding: "0px 0px 0px 32px",
    borderLeft: `1px dashed ${theme.palette.grey["300"]}`,
  },
  greyText: {
    color: theme.palette.grey["500"],
  },
  caption: {
    textTransform: "uppercase",
    fontSize: "10px",
  },
  switch: {
    display: "flex",
    justifyContent: "flex-end",
  },
  menu: {
    display: "flex",
    justifyContent: "flex-end",
  },
  ruleMenu: {
    border: `1px solid ${theme.palette.grey["200"]}`,
    boxShadow: "0px 0px 32px 2px rgba(239,239,239,0.8)",
    width: "100px",
    borderRadius: "8px",
  },
  menuItem: {
    borderBottom: `1px solid ${theme.palette.grey["200"]}`,
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
  },
  menuItemIcon: {
    fontSize: "16px",
    marginRight: "8px",
    color: theme.palette.grey["500"],
  },
}));

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 46,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 2.9,
    "&$checked": {
      transform: "translateX(21px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#0b78fa",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  customThumb: {
    width: 20,
    height: 20,
    padding: "2px",
    background: "white",
    borderRadius: "100%",
    fontSize: 10,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey["400"],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      defaultChecked
      icon={
        <CloseIcon
          variant="filled"
          style={{ color: "#9e9e9e" }}
          className={classes.customThumb}
        />
      }
      checkedIcon={
        <CheckIcon
          style={{ color: "#0b78fa" }}
          className={classes.customThumb}
        />
      }
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export function Rule(props) {
  const { ruleName, rule = "", updatedate, createdBy = "Admin", callBack = () => null } = props.rule;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({ open: false, editData: null, isclone: false });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = (isclone) => {
    handleClose()
    setState({ open: true, editData: props.rule, isclone: isclone })
  };

  return (
    <div className={classes.rule}>
      <div className={classes.ruleInfo}>
        <Typography variant="subtitle2">{ruleName}</Typography>
        <Typography
          variant="body2"
          style={{ fontSize: "12px" }}
          className={`${classes.greyText}`}
        >
          {rule}
        </Typography>
      </div>
      <div className={classes.switch}>
        <CustomSwitch name="checkedB" />
      </div>
      <div className={classes.info}>
        <div className={classes.eachInfo}>
          <Typography
            variant="caption"
            className={`${classes.greyText} ${classes.caption}`}
          >
            Last Modified:
          </Typography>
          <Typography variant="subtitle2">{moment.unix(updatedate).fromNow()}</Typography>
        </div>
        <div className={classes.eachInfo}>
          <Typography
            variant="caption"
            className={`${classes.greyText} ${classes.caption}`}
          >
            Created By:
          </Typography>
          <Typography variant="subtitle2">{createdBy}</Typography>
        </div>
      </div>
      <div className={classes.menu}>
        <MoreVertIcon
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ fontSize: "22px", color: "gray" }}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            style: { padding: 0 },
          }}
          PaperProps={{
            className: classes.ruleMenu,
          }}
        >
          <MenuItem className={classes.menuItem} onClick={() => handleEdit(false)}>
            <EditOutlinedIcon className={classes.menuItemIcon} />
            <Typography
              variant="body2"
              className={classes.greyText}
              color="initial"
            >
              Edit
            </Typography>
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={() => handleEdit(true)}>
            <FileCopyOutlinedIcon
              className={classes.menuItemIcon}
              fontSize="small"
            />
            <Typography
              className={classes.greyText}
              variant="body2"
              color="initial"
            >
              Clone
            </Typography>
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={handleClose}>
            <DeleteOutlineOutlinedIcon
              className={classes.menuItemIcon}
              fontSize="small"
            />
            <Typography
              className={classes.greyText}
              variant="body2"
              color="initial"
            >
              Delete
            </Typography>
          </MenuItem>
        </Menu>
      </div>
      <CreateRule isCloning={state.isclone} open={state.open} editData={state.editData} handleClose={() => {
        setState({ open: false, editData: null, isclone: false })
        callBack()
      }} />
    </div>
  );
}
