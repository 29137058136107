import React from "react";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import RuleProperty from "./ruleProperty";


const styles = makeStyles((theme) => ({
    newFilterParent: {
        height: "70px",
        position: "relative"
    },
    FilterParent: {
        height: "86px",
        position: "relative"
    },
    newFilter: {
        position: "absolute",
        top: 25,
        left: "38%",
        borderRadius: 19
    },
    swicthParent: {
        width: 125,
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        border: "1px solid " + theme.palette.primary.main,
        borderRadius: "20px",
        height: 36,
        position: "absolute",
        left: "40.5%",
        top: 25,
        background: "white",
        zIndex: 8,
        "& .select": {
            color: "white",
            background: theme.palette.primary.main,
        }
    },
    swicth: {
        margin: "auto 2px auto 3px",
        textAlign: "center",
        borderRadius: 16,
        paddingTop: 3,
        paddingBottom: 3,
        cursor: "pointer"
    }
}));

const addRule = { condition: "all", data: [{ condition: "all", is: null, value: null }] }

function AddRuleProperty(props) {
    const classes = styles();
    const { propertyRule = [{}], factsList = [], addPoperty = () => null, stateName = "" } = props;


    const addNewProps = () => {
        let data = JSON.parse(JSON.stringify(propertyRule))
        data.push(addRule)
        addPoperty(stateName, data)
    }
    const changeCond = (cond, index) => {
        let data = JSON.parse(JSON.stringify(propertyRule))
        data[index]["condition"] = cond
        addPoperty(stateName, data)
    }
    const updateRule = (rule, index) => {
        let data = JSON.parse(JSON.stringify(propertyRule))
        data[index]["data"] = rule
        addPoperty(stateName, data)
    }
    return (
        <React.Fragment>
            {
                propertyRule.map((val, index) => (<React.Fragment>
                    {(val.condition && index !== 0) && <Grid className={classes.FilterParent + ' filter'}>
                        {/* <Button color="primary" variant="outlined" className={classes.newFilter +" switchFilter"}>+ Add New Filter</Button> */}
                        <Grid className={classes.swicthParent + " switchParent"}>
                            <Typography onClick={() => changeCond("all", index)} className={`${classes.swicth} ${val.condition === "all" ? "select" : ''}`}>AND</Typography>
                            <Typography onClick={() => changeCond("any", index)} className={`${classes.swicth} ${val.condition === "any" ? "select" : ''}`}>OR</Typography>
                        </Grid>
                    </Grid>}
                    <RuleProperty
                        factsList={factsList}
                        updateRule={updateRule}
                        actionsList={val.data}
                        condition={val.condition === "all" ? "AND" : "OR"}
                        ruleindex={index}
                    />
                </React.Fragment>
                ))
            }
            <Grid className={classes.newFilterParent + ' newFiler'}>
                <Button color="primary" variant="outlined" className={classes.newFilter} onClick={() => addNewProps()}>+ Add New Filter</Button>
            </Grid>
        </React.Fragment>
    )
}

export default (AddRuleProperty);