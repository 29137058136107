const ColumnType = (type, value) => {
    switch (type) {
        case "number": {
            return parseInt(value) ? parseInt(value) : 0;
        }
        default: {
            return value
        }
    }
}

let getName = (name, fact, label) => {
    let dimensionName = `dimension${fact[name]?.columnName ?? ""}`;
    let Name = null
    if (fact[dimensionName]) {
        Name = getName(dimensionName, fact, fact[name]?.columnName ?? "")
    }
    return `${label ? `.${label}` : ""}${Name ? Name : ""}`
}

let getPath = (factsList, factName, label, json) => {
    let fact = factsList.filter(val => val.name === factName)[0];
    let name = null
    let dimensionName = `dimension${fact?.if?.columnName ?? ""}`;
    if (fact[dimensionName]) {
        name = getName(dimensionName, fact, fact?.if?.columnName ?? "")
    }
    return `${label}${name ? name : ""}`
}

let getFactName = (label, fact, column) => {
    let dimensionName = `dimension${label}`;
    if (fact[dimensionName]) {
        return getFactName(fact[dimensionName].columnName, fact, fact[dimensionName])
    } else {
        return column
    }
}

let factName = (factName, factsList, column) => {
    let fact = factsList.filter(val => val.name === factName)[0];
    let dimensionName = `dimension${fact?.if?.columnName ?? ""}`;
    if (fact?.if?.columnType === "dimension" && fact[dimensionName]) {
        return getFactName(fact[dimensionName].columnName, fact, fact[dimensionName])
    } else {
        return column
    }
}

export const ruleToJson = (rule, factsList) => {
    let arr = [];
    rule.map((val, index) => {
        let json = {};
        let Json = val.data[0];
        delete Json.condition
        let factDetails = factName(Json?.name?.name, factsList, Json?.name?.if);
        json[val.condition] = [{
            fact: factDetails.columnName,
            path: getPath(factsList, Json?.name?.name, Json?.name?.in?.label, Json),
            operator: Json?.is?.value,
            value: ColumnType(factDetails.columnType, Json.value)
        }];
        val.data.map((value, i) => {
            if (i !== 0) {
                json[val.condition] = pushData(json[val.condition], i - 1, value, factsList);
            }
            return false;
        })
        arr.push(json)
        return false;
    });
    return arr;
}

const pushData = (data, index, value, factsList) => {
    if (index === 0) {
        let Json = {};
        let _Json = JSON.parse(JSON.stringify(value));
        delete _Json.condition
        let factDetails = factName(value?.name?.name, factsList, value?.name?.if);
        Json[value.condition] = [{
            fact: factDetails.columnName,
            path: getPath(factsList, value?.name?.name, value?.name?.in?.label, value),
            operator: value?.is?.value,
            value: ColumnType(factDetails.columnType, value.value)
        }];
        data.push(Json)
    } else {
        let keys = Object.keys(data[1]);
        data[1][keys] = pushData(data[1][keys], index - 1, value, factsList)
    }
    return data;
}

export const jsonToRule = (json) => {
    let arr = [];
    json.map(val => {
        let keys = Object.keys(val)[0];
        let list = {
            condition: keys,
            data: [val[keys][0]]
        }
        if (val[keys][1]) {
            let array = getArray(val[keys][1])
            list.data = [...list.data, ...array];
        }
        arr.push(list);
        return false;
    })
    return arr;
}

const getArray = (_j) => {
    let keys = Object.keys(_j)[0];
    let arr = [{ condition: keys, ..._j[keys][0] }];
    if (_j[keys][1]) {
        let array = getArray(_j[keys][1]);
        arr = [...arr, ...array];
    }
    return arr;
}



