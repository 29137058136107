import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
    large: {
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        position: "fixed",
        right: "725px",
        cursor: "pointer",
        top: 10,
        "& img": {
            objectFit: "scale-down",
            backgroundColor: "#fff"
        },
        [theme.breakpoints.down("xs")]: {
            right: "313px",
        },
    },
}));

export default styles;
