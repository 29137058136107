import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Avatar, Popover, MenuList, MenuItem } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography } from "@material-ui/core";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: "1px solid #ebebeb",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },

  layoutLeftIcon: {
    width: "18px",
    height: "14px",
    border: "2px solid",
    borderRadius: "2px",
    borderLeftWidth: "6px",
  },
  layoutRightIcon: {
    width: "18px",
    height: "14px",
    border: "2px solid",
    borderRadius: "2px",
    borderRightWidth: "6px",
  },
  sizeRiplle: {
    padding: "4px",
    borderRadius: 2,
  },
  active: {
    backgroundColor: "#3f51b542",
  },
  userMenu: {
    cursor: "pointer",
    display: "flex",
  },
  profilePic: {
    width: "24px",
    height: "24px",
    borderRadius: "5px",
  },
  profileName: {
    margin: "auto",
    padding: "0px 0px 0px 8px",
  },
  header: {
    minHeight: "38px",
  },
  versionNo: {
    paddingLeft: "10px",
    fontSize: "10px",
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={classes.root}>
      <AppBar position="static" color={"inherit"} elevation={0}>
        <Toolbar variant="dense" className={classes.header}>
          <Typography variant="h6" color="primary">
            QDM
          </Typography>
          <div className={classes.grow} />
          <div>
            <div className={classes.userMenu} onClick={handleClick}>
              <Avatar
                className={classes.profilePic}
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
              />
              <Typography
                style={{ fontSize: "14px" }}
                className={classes.profileName}
                variant="subtitle2"
              >
                Clara M
              </Typography>
              <ArrowDropDownRoundedIcon
                fontSize={"default"}
                style={{ marginTop: "2px" }}
                color={"action"}
              />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {/* UserProfile details */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuList>
          <MenuItem>Profile</MenuItem>
          <MenuItem>My account</MenuItem>
          <MenuItem>Logout</MenuItem>
        </MenuList>
      </Popover>
    </div>
  );
}
