import React from "react";
import { Grid, Divider, makeStyles, Typography } from "@material-ui/core";
import { SelectBox } from "../";

const styles = makeStyles((theme) => ({
    divider: {
        margin: "15px 0px",
        borderTop: "dotted 1.5px #868686",
        backgroundColor: "unset"
    },
    remove: {
        color: "red",
        paddingLeft: 3,
        paddingTop: 10,
        cursor: "pointer",
        fontSize: 11,
        "& img": {
            width: 8,
            marginRight: 4
        }
    },
    addNew: {
        fontSize: 14,
        cursor: "pointer"
    }
}))

const intialaction = { action: null, priority: null };

function ActionsResponse(props) {
    const classes = styles();
    const { actionsList = [], stateName = "", setActions = () => null } = props;
    const updateActions = () => {
        let data = JSON.parse(JSON.stringify(actionsList));
        data.push(intialaction)
        setActions(stateName, data)
    }
    const removeActions = (index) => {
        let data = JSON.parse(JSON.stringify(actionsList));
        data.splice(index, 1)
        setActions(stateName, data)
    }
    return (
        <React.Fragment>
            {actionsList.map((val, index) => <React.Fragment>
                {index !== 0 && <Divider className={classes.divider} />}
                <Grid container>
                    <Grid md={4} style={{ marginRight: 15 }}>
                        <SelectBox
                            list={[]}
                            value={val.actions}
                            placeholder="Actions"
                        />
                    </Grid>
                    <Grid md={4}>
                        <SelectBox
                            list={[]} onchange={() => null}
                            value={val.priority}
                            placeholder="Priority"
                        />
                    </Grid>
                </Grid>
                {index !== 0 && <Typography onClick={() => removeActions(index)} className={classes.remove}><img alt="" src="/images/trash.svg" />Remove</Typography>}
            </React.Fragment>)}
            <Typography onClick={() => updateActions()} className={classes.addNew} color="primary" variant="h6" style={{ paddingTop: actionsList.length > 0 ? 10 : 0 }}>+ Add New Action</Typography>
        </React.Fragment>
    )
}

export default ActionsResponse;