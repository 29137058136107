import React from "react";
import { Grid, Divider, makeStyles, Typography } from "@material-ui/core";
import RuleSection from "./ruleSections";


const styles = makeStyles((theme) => ({
    divider: {
        margin: "35px 0px",
        borderTop: "dotted 1.5px #868686",
        backgroundColor: "unset"
    },
    remove: {
        color: "red",
        paddingLeft: 3,
        paddingTop: 10,
        cursor: "pointer",
        fontSize: 11,
        "& img": {
            width: 8,
            marginRight: 4
        }
    },
    addNew: {
        fontSize: 14,
        cursor: "pointer"
    },
    rulesParent: {
        background: "#F6F6F8",
        padding: "15px",
        borderRadius: "8px"
    },
    swicthParent: {
        width: 121,
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        border: "dotted 1.5px #868686",
        borderRadius: "20px",
        height: 36,
        position: "absolute",
        left: "41.5%",
        top: -17,
        background: "white",
        zIndex: 8,
        "& .select": {
            color: "white",
            background: theme.palette.primary.main,
        }
    },
    swicth: {
        margin: "auto",
        textAlign: "center",
        borderRadius: 16,
        paddingTop: 3,
        paddingBottom: 3,
        width: 55,
        cursor: "pointer"
    }
}))

const initailData = { in: null, if: null, is: null, value: null, condition: "all" };

function RuleProperty(props) {
    const classes = styles();

    const {
        actionsList = [initailData], factsList = [], ruleindex = 0, updateRule = () => null,
        // condition = "OR"
    } = props;

    const addNewRow = () => {
        const data = JSON.parse(JSON.stringify(actionsList));
        data.push(initailData);
        updateRule(data, ruleindex)
    }
    const changeCond = (cond, index) => {
        const data = JSON.parse(JSON.stringify(actionsList));
        data[index]["condition"] = cond
        updateRule(data, ruleindex)
    }
    const removeRow = (index) => {
        const data = JSON.parse(JSON.stringify(actionsList));
        data.splice(index, 1)
        updateRule(data, ruleindex)
    }

    return (
        <Grid className={classes.rulesParent}>
            {Array.isArray(actionsList) && actionsList.map((val, index) => <React.Fragment>
                {index !== 0 && <Grid style={{ position: "relative" }}>
                    <Grid className={classes.swicthParent}>
                        <Typography onClick={() => changeCond("all", index)} className={`${classes.swicth} ${val.condition === "all" ? "select" : ''}`}>AND</Typography>
                        <Typography onClick={() => changeCond("any", index)} className={`${classes.swicth} ${val.condition === "any" ? "select" : ''}`}>OR</Typography>
                        {/* <Typography className={`${classes.swicth}`}>{condition}</Typography> */}
                    </Grid>
                    <Divider className={classes.divider} />
                </Grid>
                }
                <RuleSection factsList={factsList} ruleindex={ruleindex} actionsList={actionsList} updateRule={updateRule} val={val} index={index} />

                {index !== 0 && <Typography onClick={() => removeRow(index)} className={classes.remove}><img alt="" src="/images/trash.svg" />Remove</Typography>}
            </React.Fragment>)}
            <Typography onClick={() => addNewRow()} className={classes.addNew} color="primary" variant="h6" style={{ paddingTop: actionsList.length > 0 ? 10 : 0 }}>+ Add New Action</Typography>
        </Grid>
    )
}

export default RuleProperty;