import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Routes from "../../../router/routes";
import { withRouter } from "react-router-dom";
import { withAllContexts } from "../../../HOCs";

const useStyles = theme => ({
    paper: {
        padding: "16px",
        marginBottom: "20px",
        border: "1px solid #cac3c3",
        boxShadow: "none",
        borderLeft: "5px solid " + theme.palette.primary.main
        // height: "69px",
    },
    editRule: {
        float: "right",
        marginLeft: "14px",
        cursor:"pointer"
    },
    title: {

        fontSize: "16px",
        fontWeight: 600,
        display: "inline"
    },
})

class List extends React.Component {
    deleteList() {
        this.props.dialog.setDialog({
            ...this.props.dialog,
            open: true,
            title: "Confirmation",
            body: "Are you sure delete the rule",
            positiveBtn: "Okay",
            negativeBtn: "No",
        })
    }
    render() {
        const { classes, title } = this.props;
        return <React.Fragment>
            <Paper className={classes.paper}>
                <Typography className={classes.title} variant="h6">{title}</Typography>
                <Typography color="primary" variant="outlined" onClick={() => this.deleteList()} className={classes.editRule}>Remove</Typography>
                <Typography color="primary" variant="outlined" onClick={() => { this.props.history.push(Routes.addrule) }} className={classes.editRule}>Edit</Typography>
            </Paper>
        </React.Fragment>
    }
}

export default withStyles(useStyles)(withRouter(withAllContexts(List)));