import React, { useState, useEffect } from 'react'
import { Grid, TextField, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import routes from "../../router/routes"
const useStyles = makeStyles(theme => ({
  textInput: {
    height: 35
  },
  fullHeight: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    backgroundColor: "#F3F6FF"
  },
  columnSection: {
    justifyContent: 'center',
    display: 'flex',
    width: 370,
    background: '#fff',
    padding: 18,
    borderRadius: '12px',
    boxShadow: '0px 5px 23px #00000014'
  },
  headerSection: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 50,
    marginTop: 4
  },
  rowView: {
    marginBottom: 20
  },
  logo: {
    width: 50,
    height: 50,
    borderRadius: 100,
    marginRight: 20
  }
}))

export default function Login(props) {

  const classes = useStyles(props);

  const [state, setState] = useState({
    email_id: "",
    password: "",
    error: {
      email_id: "",
      password: "",
    },
    message: ''
  })
  useEffect(() => {
    if (sessionStorage.getItem('isLogin')) {
      props.history.push(routes.home)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const history = useHistory();
  const validate = () => {

    let isValidated = true;

    if (!state.email_id) {
      isValidated = false;
      state.error.email_id = "Enter email id";
    }

    if (!state.password) {
      isValidated = false;
      state.error.password = "Enter password";
    }

    setState({ ...state })
    return isValidated;
  }

  const handleLogin = async () => {

    if (!validate()) {
      return false;
    }

    if (state.password === "admin1234" && state.email_id === "admin@crayond.com") {
      sessionStorage.setItem('isLogin', 1);
      history.push(routes.home);
    } else {
      setState({
        ...state,
        message: "Invalid Credentials"
      })
    }



    // if (params.email === "admin@ainqa.com" && params.password === "ainqa@12345") {
    //   // window.location.href = routes.home;
    //   history.push(routes.home);
    // }else{
    //   setState({
    //     ...state,
    //     message : "Invalid Credentials"
    //   })
    // }
  }

  const keydownFunc = (event, sectionId) => {
    if (event.keyCode === 13) {
      handleLogin()
    }
  }



  return (
    <div className={classes.fullHeight}>

      <Grid container className={classes.columnSection}>
        <Grid>
        </Grid>
        <Grid item xs={12} className={classes.headerSection}>
          <Typography variant='h6' color='primary'>QDM RULE BUILDER <span style={{ paddingLeft : 5, fontSize:10 }}>v0.0.1</span></Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>

          <div className={classes.rowView}>
            <TextField
              fullWidth
              label="Email Id"
              variant="standard"
              value={state.email_id}
              placeholder={'rulebuilder@example.com'}
              error={state.error.email_id}
              helperText={state.error.email_id}
              onChange={(e) => setState({ ...state, email_id: e.target.value, error: { ...state.error, email_id: "" }, message: "" })}
            />
          </div>

          <div className={classes.rowView}>

            <TextField
              fullWidth
              variant='standard'
              label="Password"
              type='password'
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                className: classes.textInput
              }}
              value={state.password}
              placeholder={'Password'}
              error={state.error.password}
              onKeyDown={(e) => keydownFunc(e)}
              helperText={state.error.password}
              onChange={(e) => setState({ ...state, password: e.target.value, error: { ...state.error, password: "" }, message: "" })}
            />

          </div>

          <div>
            <Typography variant="body1" gutterBottom align="center" color="error">
              {state.message}
            </Typography>
          </div>

          <div className={classes.rowView}>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              // disabled={loading}
              onClick={handleLogin}
            >
              Log In
            </Button>
          </div>

        </Grid>

      </Grid>

    </div>
  )
}
