import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DescriptionIcon from "@material-ui/icons/Description";
import SettingsIcon from "@material-ui/icons/Settings";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    background: "white",
    height: "calc(100vh - 92px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(0.5),
    borderRight: `1px solid ${theme.palette.grey["200"]}`,
  },
  navItem: {
    textAlign: "center",
    color: theme.palette.grey["500"],
    padding: "14px",
  },
  activeNavItem: {
    color: theme.palette.primary.main,
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.default,
  },
}));

export function SideBar(props) {
  const classes = useStyles();
  return (
    <div className={classes.sidebar}>
      <div className={`${classes.navItem} ${classes.activeNavItem}`}>
        <DescriptionIcon fontSize="small" variant="outlined" />
        <Typography variant="subtitle2" style={{ fontSize: "12px" }}>
          Rules
        </Typography>
      </div>
      <div className={classes.navItem}>
        <SettingsIcon fontSize="small" variant="outlined" />
        <Typography variant="subtitle2" style={{ fontSize: "12px" }}>
          Settings
        </Typography>
      </div>
    </div>
  );
}
