import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { Typography, InputAdornment, Toolbar, Button, AppBar, TextField } from "@material-ui/core";
import { CreateRule } from "../";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
      borderBottom: "1px solid #ebebeb",
    },
    textField: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      width: "240px",
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    inputField: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      fontSize: "14px",
    },
  };
});

export default function MainAppBar(props) {
  const [open, setOpen] = React.useState(false);
  const { addRule } = props;
  const classes = useStyles();
  return (
    <div>
      <AppBar
        position="static"
        color={"inherit"}
        style={{ justify: "center" }}
        elevation={0}
        className={classes.root}
      >
        <Toolbar variant="dense">
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Rule Builder
          </Typography>
          <TextField
            placeholder="Search"
            type="text"
            variant="outlined"
            size="small"
            margin="dense"
            className={classes.textField}
            InputProps={{
              classes: {
                input: classes.inputField,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
            }}
          />
          {addRule && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ textTransform: "capitalize" }}
              disableElevation
              onClick={() => {
                setOpen(true);
              }}
            >
              New Rule
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <CreateRule open={open} handleClose={() => setOpen(false)} />
    </div>
  );
}
